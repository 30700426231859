<template>
  <ul class="w-full h-15 bg-light hidden lg:flex items-center justify-evenly text-base font-bold">
    <li>
      <Link 
        :href="translatePath(ROUTES.ADS)"
        class="text-primary-dark hover:text-primary-dark-hover focus:text-primary-dark-hover hover:underline"
        active-class="underline"
      >
        {{ $t('text-all-ads') }}
      </Link>
    </li>
    <li
      v-for="category in categories.eshop"
      :key="category.id"
    >
      <Link 
        :href="`${translatePath(ROUTES.CATEGORY)}/${slugify(category?.id, category?.name)}`"
        class="text-primary-dark hover:text-primary-dark-hover focus:text-primary-dark-hover hover:underline"
        active-class="underline"
      >
        {{ category.name }}
      </Link>
    </li>
  </ul>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useCategoryStore } from '@stores/category';
import Link from "@components/ui/link/link";

const categories = useCategoryStore();

</script>
